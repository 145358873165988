<template>
    <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">
        <LayoutHeader :onboarding="true" />
        <div class="page-content">
            <slot />
        </div>
    </Html>
</template>

<script setup lang="ts">
const head = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true,
});

const htmlAttrs = computed(() => head.value.htmlAttrs!);
</script>
